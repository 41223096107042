export default class SysDepartEntity {
    
    /**
     * @type {string}
     */
    id
        
    
    /**
     * @type {string}
     */
    parentId
        
    
    /**
     * @type {string}
     */
    departName
        
    
    /**
     * @type {string}
     */
    departNameEn
        
    
    /**
     * @type {string}
     */
    departNameAbbr
        
    
    /**
     * @type {number}
     */
    departOrder
        
    
    /**
     * @type {string}
     */
    description
        
    
    /**
     * @type {string}
     */
    orgCategory
        
    
    /**
     * @type {string}
     */
    orgType
        
    
    /**
     * @type {string}
     */
    orgCode
        
    
    /**
     * @type {string}
     */
    mobile
        
    
    /**
     * @type {string}
     */
    fax
        
    
    /**
     * @type {string}
     */
    address
        
    
    /**
     * @type {string}
     */
    brief
        
    
    /**
     * @type {string}
     */
    chargeName
        
    
    /**
     * @type {string}
     */
    chargePhone
        
    
    /**
     * @type {string}
     */
    publicAccount
        
    
    /**
     * @type {string}
     */
    creditCode
        
    
    /**
     * @type {string}
     */
    legalName
        
    
    /**
     * @type {string}
     */
    legalPhone
        
    
    /**
     * @type {string}
     */
    businessLicense
        
    
    /**
     * @type {string}
     */
    memo
        
    
    /**
     * @type {string}
     */
    status
        
    
    /**
     * @type {string}
     */
    delFlag
        
    
    /**
     * @type {string}
     */
    qywxIdentifier
        
    
    /**
     * @type {string}
     */
    createBy
        
    
    /**
     * @type {number}
     */
    createTime
        
    
    /**
     * @type {string}
     */
    updateBy
        
    
    /**
     * @type {number}
     */
    updateTime
        
    
    /**
     * @type {number}
     */
    tenantId
        
    
    /**
     * @type {string}
     */
    sysOrgCode
        

    /**
     * 创建一个实例
     * @return SysDepartEntity
     */
    static createInstance(vars) {
        const entity = new SysDepartEntity()
        for (let k in entity) {
            entity[k] = vars[k]
        }
        return entity
    }
}