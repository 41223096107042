import BaseErrorAbstract from '../common/BaseErrorAbstract'

// 请求异常错误，代表http状态码400+以上的错误
export default class RequireError extends BaseErrorAbstract {
    constructor(message) {
        super(message)
    }

    handle() {
        wx.showToast({
            title: '网络或服务繁忙，请稍后重试！',
            icon: 'none'
        })
        super.handle()
    }
}
