import Require from '../plugins/Require'
import DictEntity from '../entity/DictEntity'
import DictStore from '../store/DictStore'

export default {
    /**
     * 获取全部数据字段
     * @return {Promise<DictEntity[]>}
     */
    async getAllDict() {
        const result = await Require.get('/sys/queryAllDict')
        const distList = []
        for (let key in (result.data.result || {})) {
            result.data.result[key].forEach(v => {
                const dictEntity = new DictEntity()
                dictEntity.parentKey = key
                dictEntity.value = v.value
                dictEntity.text = v.text
                dictEntity.label = v.label
                dictEntity.title = v.title
                distList.push(dictEntity)
            })
        }
        return distList
    }
}
