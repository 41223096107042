import JsSdk from '@/plugins/JsSdk'
import Require from '@/plugins/Require'

export default {
    /**
     * 初始化JSSDK的config
     * @return {Promise<void>}
     */
    initConfig() {
        return new Promise(resolve => {
            Require.get(`/sys/getJsapiTicket?url=${window.encodeURIComponent(location.href.split('#')[0])}&appid=${process.env.VUE_APP_WECHAT_APP_ID}`).then(result => {
                JsSdk.config({
                    debug: false,
                    appId: result.data.result.appId,
                    nonceStr: result.data.result.nonceStr,
                    signature: result.data.result.signature,
                    timestamp: result.data.result.timestamp,
                    jsApiList: ['scanQRCode', 'chooseImage', 'previewImage', 'uploadImage', 'getLocalImgData', 'openAddress', 'chooseWXPay', 'updateAppMessageShareData', 'onMenuShareAppMessage', 'updateTimelineShareData']
                })
                JsSdk.ready(() => {
                    resolve()
                    JsSdk.updateAppMessageShareData({
                        title: '测试',
                        desc: '邀请您访问',
                        link: location.origin,
                        imgUrl: 'https://profile.csdnimg.cn/4/C/0/1_niubitianping',
                        success(e) {
                            console.log(e)
                        },
                        fail(e) {
                            console.log(e)
                        }
                    })
                    JsSdk.onMenuShareAppMessage({
                        title: '测试', // 分享标题
                        link: location.origin, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                        imgUrl: 'https://profile.csdnimg.cn/4/C/0/1_niubitianping', // 分享图标
                        success: function () {
                            // 用户点击了分享后执行的回调函数
                        },
                        fail(e) {
                            console.log(e)
                        }
                    })
                })
            })
        })
    }
}
