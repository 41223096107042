/**
 * 租赁租约类型
 */
export default {
    // 租赁
    LEASE: '01',
    LEASE__LABEL: '租赁',
    // 出售
    SELL: '02',
    SELL__LABEL: '出售',
    // 托管
    HOSTING: '03',
    HOSTING__LABEL: '托管'
}
