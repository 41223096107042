import VisVisitorEntity from '@/support/v1/entity/VisVisitorEntity'
import VisVisitorPermissionEntity from '@/support/v1/entity/VisVisitorPermissionEntity'

export default class VisVisitorApplyEntity {

    /**
     * @type {number}
     */
    id


    /**
     * @type {number}
     */
    receptionistId


    /**
     * @type {string}
     */
    receptionistRealName


    /**
     * @type {string}
     */
    receptionistPhone


    /**
     * @type {string}
     */
    receptionistDepartId


    /**
     * @type {string}
     */
    receptionistDepartName


    /**
     * @type {string}
     */
    visitStartTime


    /**
     * @type {string}
     */
    visitEndTime


    /**
     * @type {string}
     */
    visitPurpose


    /**
     * @type {number}
     */
    personNum


    /**
     * @type {number}
     */
    defaultPrivilegeGroupFlag


    /**
     * @type {string}
     */
    noRegistrationFlag


    /**
     * @type {string}
     */
    processInstanceId


    /**
     * @type {string}
     */
    bpmStatus


    /**
     * @type {number}
     */
    tenantId


    /**
     * @type {string}
     */
    createBy


    /**
     * @type {number}
     */
    createTime


    /**
     * @type {string}
     */
    updateBy


    /**
     * @type {number}
     */
    updateTime


    /**
     * @type {number}
     */
    version

    /**
     * 申请时间
     * @type {string}
     */
    applyTime

    // vo-------------------------

    /**
     * 关联的申请用户
     * @type {VisVisitorEntity}
     */
    visVisitorEntity


    /**
     * @type {VisVisitorPermissionEntity[]}
     */
    visitorPermissionList = []

    /**
     * 流程状态  00-待提交 01-处理中 02-已完成 03-已作废 04-已取回 05-已关闭 06-已驳回
     * @type {{}}
     */
    static bpmStatusEnum = {
        NOT_SUBMIT: '00',
        NOT_SUBMIT__LABEL: '待提交',

        PROCESSING: '01',
        PROCESSING__LABEL: '处理中',

        FINISH: '02',
        FINISH__LABEL: '已完成',

        VOIDED: '03',
        VOIDED__LABEL: '已作废',

        RETRIEVED: '04',
        RETRIEVED__LABEL: '已撤回',

        REJECT: '05',
        REJECT__LABEL: '已驳回'
    }

    /**
     * 创建一个实例
     * @return VisVisitorApplyEntity
     */
    static createInstance(vars) {
        const entity = new VisVisitorApplyEntity()
        for (let k in entity) {
            if (k === 'visVisitorEntity') {
                if (vars['visVisitorEntity']) {
                    entity.visVisitorEntity = VisVisitorEntity.createInstance(vars[k])
                }
            } else if (k === 'receptionistPhone') {
                if ((vars['receptionistPhone'] || '').length > 4) {
                    entity.receptionistPhone = vars['receptionistPhone'].substring(7, 12)
                } else {
                    entity.receptionistPhone = vars['receptionistPhone']
                }
            } else if (k === 'visitorPermissionList') {
                entity.visitorPermissionList = (vars['visitorPermissionList'] || []).map(vars => VisVisitorPermissionEntity.createInstance(vars))
            } else {
                entity[k] = vars[k]
            }
        }
        return entity
    }
}
