// 数据字典仓库
import DictService from '../services/DictService'
import LeaseStateEnum from '../enums/LeaseStateEnum'
import BillTypeEnum from '../enums/BillTypeEnum'
import LeaseContractTypeEnum from '../enums/LeaseContractTypeEnum'

export default class DictStore {
    /**
     * @type {DictEntity[]|null}
     * @private
     */
    static _store = null

    /**
     * 上一次更新数据字典的时间戳
     * @type {number}
     */
    static _updateTimestamp = 0

    /**
     * 获取数据字典
     * @return {Promise<DictEntity[]>}
     */
    static async get() {
        // 如果是空的情况下，就从后台获取在返回。如果上次获取实在10分钟前，就重新获取
        if (!DictStore._store || new Date().getTime() - DictStore._updateTimestamp > 1000 * 60 * 10) {
            DictStore.set(await DictService.getAllDict())
            DictStore._updateTimestamp = new Date().getTime()
        }
        return DictStore._store
    }

    /**
     * 根据数据字典类型的key获取这个类型下的数据字典
     * @param parentKey {string} 数据字典类型的key
     * @return {Promise<DictEntity[]>}
     */
    static async getByTypeKey(parentKey) {
        return (await DictStore.get()).filter(dictEntity => dictEntity.parentKey === parentKey)
    }

    /**
     * 根据数据字典类型的父类型与数据字典的value获取数据字典，可能是null
     * @param parentKey {string} 数据字典类型的key
     * @param value {string} 数据字典的value
     * @return {Promise<DictEntity>}
     */
    static async getByParentKeyAndValue(parentKey, value) {
        return (await DictStore.get()).filter(dictEntity => dictEntity.parentKey === parentKey).find(dictEntity => dictEntity.value === value)
    }

    /**
     * 初始化配置的label
     * @return {Promise<void>}
     */
    static async initConfigLabel() {
        const distList = await DictService.getAllDict()
        // 下面的代码是根据数据字典的parentKey逐个对应上Config的值
        // 设置LeaseStateEnum的label
        distList.filter(dictEntity => dictEntity.parentKey === 'room_state').forEach(dictEntity => {
            for (let key in LeaseStateEnum) {
                if (LeaseStateEnum[key] === dictEntity.value) {
                    LeaseStateEnum[`${key}__LABEL`] = dictEntity.text
                }
            }
        })
        // 设置BillTypeEnum的label
        distList.filter(dictEntity => dictEntity.parentKey === 'bill_code').forEach(dictEntity => {
            for (let key in BillTypeEnum) {
                if (BillTypeEnum[key] === dictEntity.value) {
                    BillTypeEnum[`${key}__LABEL`] = dictEntity.text
                }
            }
        })
        // 设置租赁合同类型
        distList.filter(dictEntity => dictEntity.parentKey === 'contract_type_code').forEach(dictEntity => {
            for (let key in LeaseContractTypeEnum) {
                if (LeaseContractTypeEnum[key] === dictEntity.value) {
                    LeaseContractTypeEnum[`${key}__LABEL`] = dictEntity.text
                }
            }
        })
    }

    /**
     * 设置数据字段
     * @param dictList {DictEntity[]}
     * @returns {void}
     */
    static set(dictList) {
        DictStore._store = dictList
    }
}
