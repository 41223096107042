// 基础错误定义
export default class BaseErrorAbstract {

    /**
     * 错误信息
     * @type {string}
     */
    message

    /**
     * 构造函数
     * @param message {string} 错误信息
     */
    constructor(message) {
        this.message = message
    }

    /**
     * 异常操作，通常需要子错误覆盖这个方法
     */
    handle() {
        throw new Error(this.message)
    }
}
