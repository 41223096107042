// 用户数据实例
export default class UserEntity {
    /**
     * id
     * @type {number}
     */
    id

    /**
     * 手机号
     * @type {string}
     */
    phone

    /**
     * 姓名
     * @type {string}
     */
    name

    /**
     * 头像
     * @type {string}
     */
    avatar

    /**
     * 角色
     */
    role

    /**
     * 是否已经填了注册申请
     * @type {string}
     */
    inputDataFlag

    /**
     * 性别
     * @type {string}
     */
    sex

    /**
     * 部门id
     * @type {string|number}
     */
    orgId

    /**
     * 部门code，约等于部门id
     * @type {string|number}
     */
    orgCode

    /**
     * 部门名称
     * @type {string}
     */
    orgName

    /**
     * 紧急联系人姓名
     * @type {string}
     */
    emergencyContactName

    /**
     * 紧急联系人电话
     * @type {string}
     */
    emergencyContactPhone

    /**
     * 户口所在地
     * @type {string}
     */
    originAddress

    /**
     * 当前居住地
     * @type {string}
     */
    nowAddress

    /**
     * 正面照
     * @type {string}
     */
    frontPhoto

    /**
     * 人脸照片
     * @type {string}
     */
    facePhoto

    /**
     * 车牌号
     * @type {string}
     */
    carNum

    /**
     * 是否为管理员用户
     * @type {boolean}
     */
    isAdminUser = false

    /**
     * 邮箱
     * @type {string|null}
     */
    email

    /**
     * 用户的租户id
     * @type {number}
     */
    tenantId
}
