export default class VisVisitorPermissionEntity {
    
    /**
     * @type {number}
     */
    id
        
    
    /**
     * @type {number}
     */
    visitorApplyId
        
    
    /**
     * @type {number}
     */
    visitorInviteId
        
    
    /**
     * @type {number}
     */
    visitorRecordId
        
    
    /**
     * @type {string}
     */
    privilegeGroupId
        
    
    /**
     * @type {string}
     */
    privilegeGroupName
        
    
    /**
     * @type {number}
     */
    tenantId
        
    
    /**
     * @type {string}
     */
    createBy
        
    
    /**
     * @type {number}
     */
    createTime
        
    
    /**
     * @type {string}
     */
    updateBy
        
    
    /**
     * @type {number}
     */
    updateTime
        
    
    /**
     * @type {number}
     */
    version
        

    /**
     * 创建一个实例
     * @return VisVisitorPermissionEntity
     */
    static createInstance(vars) {
        const entity = new VisVisitorPermissionEntity()
        for (let k in entity) {
            entity[k] = vars[k]
        }
        return entity
    }
}