import Axios from 'axios'
import LogoutError from '@/errors/LogoutError'
import RequireError from '@/errors/RequireError'
import SystemError from '@/errors/SystemError'


const require = Axios.create({
    baseURL: process.env.VUE_APP_SERVER_BASE_PATH
})

require.interceptors.request.use(async config => {
    config.headers['tenant-id'] = sessionStorage.getItem('tenant-id') || process.env.VUE_APP_TENANT_ID
    config.headers['X-Access-Token'] = sessionStorage.getItem('X-Access-Token') || localStorage.getItem('X-Access-Token')
    config.headers['If-Modified-Since'] = 0
    config.headers['Cache-Control'] = 'no-cache'
    return config
})

require.interceptors.response.use(response => {
    console.log(response.data)
    if (response.data instanceof Blob) return response
    switch (response.data.code) {
        case 0:
        case 200:
            return response
        case 401:
            throw new LogoutError('登陆过期，请重新登陆！')
        case 412:
            throw new RequireError('验证码错误！')
        case 500:
            throw new SystemError(response.data.message)
        case 510:
            throw new SystemError(response.data.message)
    }
}, error => {
    if (error.response) {
        switch (error.response.data.code) {
            case 401:
                throw new LogoutError('登陆过期，请重新登陆！')
            case 412:
                throw new RequireError('验证码错误！')
            case 500:
                throw new SystemError(error.response.data.message)
        }
    }
    throw new SystemError(error.message)
})

export default require
