/**
 * 租赁状态
 */
export default {
    LEASE: '02', // 已满
    NOT_FULL: '01', // 未满
    NOT_LEASE: '00', // 空房
    SOLD: '03', // 已售
    HOSTING: '04' // 已托管
}
