// 错误统一操作
import BaseErrorAbstract from './BaseErrorAbstract'
import VanToast from 'vant/lib/toast'

export default class ErrorHandle {
    /**
     * 错误操作
     * @param error {Error}
     */
    static handle(error) {
        if (error instanceof BaseErrorAbstract) {
            error.handle()
        } else {
            VanToast({
                message: error.message || '网络繁忙，请稍后重试！'
            })
            throw error
        }
    }
}
