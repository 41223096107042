import BaseErrorAbstract from '../common/BaseErrorAbstract'
import VanToast from 'vant/es/toast'

// 系统错误，可能是小程序错误，也可能是服务端错误，反正只需要提示错误就行
export default class SystemError extends BaseErrorAbstract {
    constructor(message) {
        super(message)
    }

    handle() {
        if (this.message) {
            VanToast({
                message: this.message
            })
        }
        super.handle()
    }
}
