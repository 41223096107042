export default class VisVisitorInviteEntity {

    /**
     * @type {number}
     */
    id


    /**
     * @type {number}
     */
    receptionistId


    /**
     * @type {string}
     */
    receptionistRealName


    /**
     * @type {string}
     */
    receptionistPhone


    /**
     * @type {string}
     */
    receptionistDepartId


    /**
     * @type {string}
     */
    receptionistDepartName


    /**
     * @type {string}
     */
    visitStartTime


    /**
     * @type {string}
     */
    visitEndTime


    /**
     * @type {string}
     */
    visitPurpose


    /**
     * @type {number}
     */
    personNum


    /**
     * @type {number}
     */
    defaultPrivilegeGroupFlag


    /**
     * @type {string}
     */
    noRegistrationFlag


    /**
     * @type {string}
     */
    processInstanceId


    /**
     * @type {string}
     */
    bpmStatus


    /**
     * @type {number}
     */
    qrCodeEffectiveDuration


    /**
     * @type {string}
     */
    qrCode


    /**
     * @type {number}
     */
    tenantId


    /**
     * @type {string}
     */
    createBy


    /**
     * @type {number}
     */
    createTime


    /**
     * @type {string}
     */
    updateBy


    /**
     * @type {number}
     */
    updateTime


    /**
     * @type {number}
     */
    version

    /**
     * 邀请人数
     * @type {number}
     */
    invitePersonNum

    /**
     * 通行权限组
     * @type {{
     *      privilegeGroupId: string,
     *      privilegeGroupName: string,
     *      remark: string,
     *      isDefault: string
     * }[]}
     */
    visitorPermissionList = []

    /**
     * 被邀请人姓名
     * @type {string}
     */
    visitorName

    /**
     * 被邀请人人脸照片
     * @type {string}
     */
    visitorPhoto

    /**
     * 访客邀请id
     * @type {number}
     */
    visitorInviteId

    /**
     * 创建一个实例
     * @return VisVisitorInviteEntity
     */
    static createInstance(vars) {
        const entity = new VisVisitorInviteEntity()
        for (let k in entity) {
            entity[k] = vars[k]
        }
        return entity
    }
}
