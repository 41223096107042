import {createRouter, createWebHistory} from 'vue-router'
import WechatService from '../services/WechatService'
import VanToast from 'vant/es/toast'
import UserStoreOld from '@/store/UserStore'
import ErrorHandle from '@/common/ErrorHandle'
import LogoutError from '@/errors/LogoutError'
import UserService from '@/services/UserService'
import RoleEnum from '@/enums/RoleEnum'

const router = createRouter({
    history: createWebHistory(process.env.VUE_APP_BASE_PATH),
    routes: [
        {
            path: '/',
            name: '/',
            component: () => import('../pages/IndexPage'),
            meta: {
                title: '首页'
            }
        },
        {
            path: '/home',
            name: '/home',
            component: () => import('../pages/HomePage'),
            meta: {
                title: '首页'
            }
        },
        {
            path: '/login',
            name: '/login',
            component: () => import('../pages/LoginPage'),
            meta: {
                title: '登陆'
            }
        },
        {
            path: '/bindPhone',
            name: '/bindPhone',
            component: () => import('../pages/BindPhonePage'),
            meta: {
                title: '绑定手机号'
            }
        },
        {
            path: '/chooseRole',
            name: '/chooseRole',
            component: () => import('../pages/ChooseRolePage'),
            meta: {
                title: '选择角色'
            }
        },
        {
            path: '/registerForm',
            name: '/registerForm',
            component: () => import('../pages/RegisterFormPage'),
            meta: {
                title: '注册申请'
            }
        },
        {
            path: '/registerApplyApprovalDetail',
            name: '/registerApplyApprovalDetail',
            component: () => import('../pages/RegisterApplyApprovalDetailPage'),
            meta: {
                title: '资料审批'
            }
        },
        {
            path: '/allApp',
            component: () => import('../pages/AllAppPage'),
            meta: {
                title: '全部应用'
            }
        },
        {
            path: '/userInfo',
            component: () => import('../pages/UserInfoPage'),
            meta: {
                title: '我的资料'
            }
        },
        {
            path: '/application/pass',
            component: () => import('../pages/application/pass/IndexPage'),
            meta: {
                title: '通行记录'
            }
        },
        {
            path: '/application/pass/uploadFace',
            component: () => import('../pages/application/pass/UploadFacePage'),
            meta: {
                title: '上传人脸'
            }
        },
        // {
        //     path: '/application/access/record',
        //     component: () => import('../pages/application/access/RecordPage'),
        //     meta: {
        //         title: '访问申请'
        //     }
        // },
        // {
        //     path: '/application/access/detail/:id',
        //     component: () => import('../pages/application/access/DetailPage'),
        //     meta: {
        //         title: '访问申请详情'
        //     }
        // },
        // {
        //     path: '/application/access/apply',
        //     component: () => import('../pages/application/access/ApplyPage'),
        //     meta: {
        //         title: '访问申请'
        //     }
        // },
        {
            path: '/application/access/detailEntourage/:id',
            component: () => import('../pages/application/access/DetailEntouragePage'),
            meta: {
                title: '同行人员'
            }
        },
        {
            path: '/application/property',
            component: () => import('../pages/application/property/IndexPage'),
            meta: {
                title: '物业反馈'
            }
        },
        {
            path: '/application/property/detail/:id',
            component: () => import('../pages/application/property/DetailPage'),
            meta: {
                title: '物业反馈详情'
            }
        },
        {
            path: '/application/carMoonInsurance/applyList',
            component: () => import('../pages/application/carMoonInsurance/ApplyListPage'),
            meta: {
                title: '包期申请'
            }
        },
        {
            path: '/application/carMoonInsurance/applyForm',
            component: () => import('../pages/application/carMoonInsurance/ApplyFormPage'),
            meta: {
                title: '包期申请'
            }
        },
        {
            path: '/application/carMoonInsurance/contract',
            component: () => import('../pages/application/carMoonInsurance/ContractPage'),
            meta: {
                title: '包期合约'
            }
        },
        {
            path: '/application/carMoonInsurance/contractDetail/:id',
            component: () => import('../pages/application/carMoonInsurance/ContractDetailPage'),
            meta: {
                title: '包期合约'
            }
        },
        {
            path: '/application/carMoonInsurance/contractRenew',
            component: () => import('../pages/application/carMoonInsurance/ContractRenewPage'),
            meta: {
                title: '我要续期'
            }
        },
        {
            path: '/application/carMoonInsurance/contractRenewForm/:contractId',
            component: () => import('../pages/application/carMoonInsurance/ContractRenewFormPage'),
            meta: {
                title: '我要续期'
            }
        },
        {
            path: '/application/carMoonInsurance/renewBillList',
            component: () => import('../pages/application/carMoonInsurance/RenewBillListPage'),
            meta: {
                title: '续期账单'
            }
        },
        {
            path: '/application/carMoonInsurance/renewBillForm/:id',
            component: () => import('../pages/application/carMoonInsurance/RenewBillFormPage'),
            meta: {
                title: '续期账单'
            }
        },
        // {
        //     path: '/application/carMoonInsurance/inAndOut/:id',
        //     component: () => import('../pages/application/carMoonInsurance/InAndOurPage'),
        //     meta: {
        //         title: '出入记录'
        //     }
        // },
        // {
        //     path: '/application/carMoonInsurance/renew/:id',
        //     component: () => import('../pages/application/carMoonInsurance/RenewPage'),
        //     meta: {
        //         title: '续签记录'
        //     }
        // },
        {
            path: '/application/lease',
            component: () => import('../pages/application/lease/IndexPage'),
            meta: {
                title: '租赁服务'
            }
        },
        {
            path: '/application/repair',
            component: () => import('../pages/application/repair/IndexPage'),
            meta: {
                title: '报修'
            }
        },
        {
            path: '/application/repair/detail/:id',
            component: () => import('../pages/application/repair/DetailPage'),
            meta: {
                title: '报修明细'
            }
        },
        {
            path: '/application/repair/evaluate/:id',
            component: () => import('../pages/application/repair/EvaluatePage'),
            meta: {
                title: '报修评价'
            }
        },
        {
            path: '/application/repairWork',
            component: () => import('../pages/application/repairWork/IndexPage'),
            meta: {
                title: '维修工单'
            }
        },
        {
            path: '/application/repairWork/detail/:id',
            component: () => import('../pages/application/repairWork/DetailPage'),
            meta: {
                title: '维修工单明细'
            }
        },
        {
            path: '/application/repairWork/success/:id',
            component: () => import('../pages/application/repairWork/SuccessPage'),
            meta: {
                title: '维修完工'
            }
        },
        {
            path: '/application/monitor',
            component: () => import('../pages/application/monitor/IndexPage'),
            meta: {
                title: '监控'
            }
        },
        {
            path: '/application/monitor/live/:code',
            component: () => import('../pages/application/monitor/LivePage'),
            meta: {
                title: '监控'
            }
        },
        {
            path: '/application/leavePark/list',
            component: () => import('../pages/application/leavePark/ListPage'),
            meta: {
                title: '离园报备'
            }
        },
        {
            path: '/application/leavePark/detail/:id',
            component: () => import('../pages/application/leavePark/DetailPage'),
            meta: {
                title: '离园报备详情'
            }
        },
        {
            path: '/application/leavePark/apply',
            component: () => import('../pages/application/leavePark/ApplyPage'),
            meta: {
                title: '离园报备'
            }
        },
        {
            path: '/application/leaveParkApproval/list',
            component: () => import('../pages/application/leaveParkApproval/ListPage'),
            meta: {
                title: '离园审批'
            }
        },
        {
            path: '/application/leaveParkApproval/detail/:id',
            component: () => import('../pages/application/leaveParkApproval/DetailPage'),
            meta: {
                title: '离园审批'
            }
        },
        {
            path: '/application/leaveParkApproval/reject/:id',
            component: () => import('../pages/application/leaveParkApproval/RejectPage'),
            meta: {
                title: '拒绝审批'
            }
        },
        {
            path: '/application/approval/list',
            component: () => import('../pages/application/approval/ListPage'),
            meta: {
                title: '审批处理'
            }
        },
        {
            path: '/application/approval/access/:id',
            component: () => import('../pages/application/approval/AccessPage'),
            meta: {
                title: '访问申请审批'
            }
        },
        {
            path: '/application/approval/repair/:id',
            component: () => import('../pages/application/approval/RepairPage'),
            meta: {
                title: '报修申请审批'
            }
        },
        {
            path: '/application/approval/register/:id',
            component: () => import('../pages/application/approval/RegisterPage'),
            meta: {
                title: '注册申请审批'
            }
        },
        {
            path: '/application/approval/vehicleContractApply/:id',
            component: () => import('../pages/application/approval/VehicleContractPage'),
            meta: {
                title: '包期申请审批'
            }
        },
        {
            path: '/application/bill',
            component: () => import('../pages/application/bill/ListPage'),
            meta: {
                title: '我的账单'
            }
        },
        // {
        //     path: '/application/bill/pay/',
        //     component: () => import('../pages/application/bill/PayPage'),
        //     meta: {
        //         title: '我的账单'
        //     }
        // },
        {
            path: '/application/bill/mergePay/:payType/:orderIds',
            component: () => import('../pages/application/bill/MergePayPage'),
            meta: {
                title: '我的账单'
            }
        },
        {
            path: '/application/bill/info/:orderId',
            component: () => import('../pages/application/bill/InfoPage'),
            meta: {
                title: '我的账单'
            }
        },
        {
            path: '/application/bill/detailMultiple/:orderIds',
            component: () => import('../pages/application/bill/DetailMultiple'),
            meta: {
                title: '我的账单'
            }
        },
        {
            path: '/application/invoiceApply/billList',
            component: () => import('../pages/application/invoiceApply/BillListPage'),
            meta: {
                title: '发票申请'
            }
        },
        {
            path: '/application/invoiceApply/apply/:billIds',
            component: () => import('../pages/application/invoiceApply/ApplyPage'),
            meta: {
                title: '发票申请'
            }
        },
        {
            path: '/application/myInvoice',
            component: () => import('../pages/application/myInvoice/ListPage'),
            meta: {
                title: '我的发票'
            }
        },
        {
            path: '/application/myInvoice/detail/:id',
            component: () => import('../pages/application/myInvoice/DetailPage'),
            meta: {
                title: '我的发票'
            }
        },
        {
            path: '/application/myInvoice/allBill/:id',
            component: () => import('../pages/application/myInvoice/AllBillPage'),
            meta: {
                title: '我的发票'
            }
        },
        {
            path: '/application/invoiceMaintenance',
            component: () => import('../pages/application/invoiceMaintenance/ListPage'),
            meta: {
                title: '发票抬头'
            }
        },
        {
            path: '/application/invoiceMaintenance/add',
            component: () => import('../pages/application/invoiceMaintenance/AddPage'),
            meta: {
                title: '新增发票抬头'
            }
        },
        {
            path: '/application/invoiceMaintenance/add/:id',
            component: () => import('../pages/application/invoiceMaintenance/AddPage'),
            meta: {
                title: '发票抬头'
            }
        },
        {
            path: '/application/ammeter',
            component: () => import('../pages/application/ammeter/IndexPage'),
            meta: {
                title: '电表'
            }
        },
        {
            path: '/application/ammeter/rechargeRecord',
            component: () => import('../pages/application/ammeter/RechargeRecordPage'),
            meta: {
                title: '电表充值记录'
            }
        },
        {
            path: '/application/ammeter/detail/:id/:num',
            component: () => import('../pages/application/ammeter/DetailPage'),
            meta: {
                title: '电表明细'
            }
        },
        {
            path: '/application/ammeter/list/:date/:meterNum',
            component: () => import('../pages/application/ammeter/ListPage'),
            meta: {
                title: '用电明细'
            }
        },
        {
            path: '/application/ammeter/recharge/:ammeterMeterId/:ammeterMeterNum',
            component: () => import('../pages/application/ammeter/RechargePage'),
            meta: {
                title: '电表充值'
            }
        },
        {
            path: '/application/water',
            component: () => import('../pages/application/water/IndexPage'),
            meta: {
                title: '水表'
            }
        },
        {
            path: '/application/water/detail/:id/:num',
            component: () => import('../pages/application/water/DetailPage'),
            meta: {
                title: '水表明细'
            }
        },
        {
            path: '/application/water/list/:date/:meterNum',
            component: () => import('../pages/application/water/ListPage'),
            meta: {
                title: '用水明细'
            }
        },
        {
            path: '/newsList/:title/:allName',
            component: () => import('../pages/NewsListPage'),
            meta: {
                title: '新闻动态'
            }
        },
        {
            path: '/newsDetail/:id',
            component: () => import('../pages/NewsDetailPage'),
            meta: {
                title: '详情'
            }
        },
        {
            path: '/appSort',
            component: () => import('../pages/AppSortPage'),
            meta: {
                title: '排序'
            }
        },
        {
            path: '/application/canteenCheckMeals',
            component: () => import('../pages/application/canteenCheckMeals/IndexPage'),
            meta: {
                title: '食堂报餐'
            }
        },
        {
            path: '/application/canteenCheckMeals/booking/:mealDate/:mealCategoryId',
            component: () => import('../pages/application/canteenCheckMeals/BookingPage'),
            meta: {
                title: '食堂报餐'
            }
        },
        {
            path: '/application/account',
            component: () => import('../pages/application/account/IndexPage'),
            meta: {
                title: '我的账户'
            }
        },
        {
            path: '/application/account/recharge',
            component: () => import('../pages/application/account/RechargePage'),
            meta: {
                title: '账户充值'
            }
        },
        {
            path: '/application/account/setting',
            component: () => import('../pages/application/account/SettingPage'),
            meta: {
                title: '支付设置'
            }
        },
        {
            path: '/application/account/payPws',
            component: () => import('../pages/application/account/PayPwsPage'),
            meta: {
                title: '支付密码'
            }
        },
        {
            path: '/application/account/record',
            component: () => import('../pages/application/account/RecordPage'),
            meta: {
                title: '账户记录'
            }
        },
        {
            path: '/application/account/rechargeRecord',
            component: () => import('../pages/application/account/RechargeRecordPage'),
            meta: {
                title: '充值记录'
            }
        },
        {
            path: '/application/account/pay',
            component: () => import('../pages/application/account/PayPage'),
            meta: {
                title: '支付'
            }
        },
        {
            path: '/application/bill/payV2/',
            component: () => import('../pages/application/bill/AbcPayV2Page'),
            meta: {
                title: '支付'
            }
        },
        {
            path: '/application/bill/abcAppPay/:token/:payId',
            name: 'abcAppPay',
            component: () => import('../pages/application/bill/AbcPayPage'),
            meta: {
                title: '欢迎使用农行掌银APP支付'
            }
        },
        {
            path: '/application/eCard',
            name: 'eCard',
            component: () => import('../pages/application/eCard/IndexPage'),
            meta: {
                title: '一卡通'
            }
        },
        {
            path: '/application/smartCanteen/info',
            component: () => import('../pages/application/smartCanteen/InfoPage'),
            meta: {
                title: '商户信息'
            }
        },
        {
            path: '/application/smartCanteen/mealStatistics',
            component: () => import('../pages/application/smartCanteen/MealStatisticsPage'),
            meta: {
                title: '报餐统计'
            }
        },
        {
            path: '/application/smartCanteen/businessStatistics',
            component: () => import('../pages/application/smartCanteen/BusinessStatisticsPage'),
            meta: {
                title: '营业统计'
            }
        },
        {
            path: '/application/smartCanteen/dishesManage',
            component: () => import('../pages/application/smartCanteen/DishesManagePage'),
            meta: {
                title: '菜品管理'
            }
        },
        {
            path: '/application/smartCanteen/addDishes',
            component: () => import('../pages/application/smartCanteen/AddDishesPage'),
            meta: {
                title: '添加菜品'
            }
        },
        {
            path: '/application/smartCanteen/booking',
            component: () => import('../pages/application/smartCanteen/BookingPage'),
            meta: {
                title: '预约管理'
            }
        },
        {
            path: '/application/smartCanteen/bookingDetail/:id',
            component: () => import('../pages/application/smartCanteen/BookingDetailPage'),
            meta: {
                title: '预约管理'
            }
        },
        {
            path: '/application/account/refundRecord',
            component: () => import('../pages/application/account/RefundRecordPage'),
            meta: {
                title: '退款记录'
            }
        },
        {
            path: '/application/account/refundDetail/:id',
            component: () => import('../pages/application/account/RefundDetailPage'),
            meta: {
                title: '退款记录'
            }
        },
        {
            path: '/application/access/record',
            name: '/application/access/record',
            component: () => import('../pages/application/access/RecordPage'),
            meta: {
                title: '访客申请',
                permission: ['guest']
            }
        },
        {
            path: '/application/access/entry',
            name: '/application/access/entry',
            component: () => import('../pages/application/access/EntryPage'),
            meta: {
                title: '访客申请',
                permission: ['guest']
            }
        },
        {
            path: '/application/access/detail/:id',
            name: '/application/access/detail/:id',
            component: () => import('../pages/application/access/DetailPage'),
            meta: {
                title: '访客申请详情',
                permission: ['guest']
            }
        },
        {
            path: '/application/access/apply',
            name: '/application/access/apply',
            component: () => import('../pages/application/access/ApplyPage'),
            meta: {
                title: '访客申请',
                permission: ['guest']
            }
        },
        {
            path: '/application/access/invite/:info',
            name: '/application/access/invite/:info',
            component: () => import('../pages/application/access/ApplyPage'),
            meta: {
                title: '访客邀请',
                permission: ['visitor_invite']
            }
        },
        {
            path: '/application/access/approvalList',
            component: () => import('../pages/application/access/ApprovalListPage'),
            meta: {
                title: '访客审批',
                permission: ['visitor_approval']
            }
        },
        {
            path: '/application/access/approval/:id/:processId',
            component: () => import('../pages/application/access/ApprovalPage'),
            meta: {
                title: '访客审批',
                permission: ['visitor_approval']
            }
        },
        {
            path: '/application/access/twoConfirm/:id/:type',
            component: () => import('../pages/application/access/TwoConfirmPage'),
            meta: {
                title: '到访确认',
                permission: ['visitor_approval']
            }
        },
        {
            path: '/application/access/invite',
            component: () => import('../pages/application/access/InvitePage'),
            meta: {
                title: '访客邀请',
                permission: ['visitor_invite']
            }
        },
        {
            path: '/application/access/invite/list',
            component: () => import('../pages/application/access/InviteListPage'),
            meta: {
                title: '访客邀请',
                permission: ['visitor_invite']
            }
        },
        {
            path: '/application/access/inviteQrcode/:id',
            name: '/application/access/inviteQrcode/:id',
            component: () => import('../pages/application/access/InviteQrcodePage'),
            meta: {
                title: '访客邀请二维码',
                permission: ['visitor_invite']
            }
        },
        {
            path: '/application/access/detailEntourage/:id',
            component: () => import('../pages/application/access/DetailEntouragePage'),
            meta: {
                title: '同行人员',
                permission: ['visitor_invite']
            }
        },
        {
            path: '/application/approval/inviteAccessToTheVisitListPage/:id',
            component: () => import('../pages/application/access/InviteAccessToTheVisitListPage'),
            meta: {
                title: '到访人员',
                permission: ['visitor_approval']
            }
        },
        {
            path: '/application/accessList',
            component: () => import('../pages/application/accessList/IndexPage'),
            meta: {
                title: '访客列表',
                permission: ['access_list']
            }
        },
        {
            path: '/application/accessList/detail/:id',
            component: () => import('../pages/application/accessList/DetailPage'),
            meta: {
                title: '访客详情',
                permission: ['access_list']
            }
        },
        {
            path: '/application/accessList/sign',
            component: () => import('../pages/application/accessList/SignPage'),
            meta: {
                title: '签到',
                permission: ['access_list']
            }
        },
        {
            path: '/application/accessList/sign/:id/:index',
            component: () => import('../pages/application/accessList/SignPage'),
            meta: {
                title: '签到',
                permission: ['access_list']
            }
        },
    ]
})

router.replace = function (url) {
    location.replace(process.env.VUE_APP_BASE_PATH + url)
}

router.push = function (url) {
    location.href = process.env.VUE_APP_BASE_PATH + url
}

router.beforeEach(async (to, from, next) => {
    // 每次跳转都清除loading弹窗
    VanToast.clear(true)

    // 进入页面的一些判断
    if (to.path === '/') {
        // 当参数上带了entry,就存进sessionStroage
        if (to.query.entry) {
            sessionStorage.setItem('entry', to.query.entry)
        }
        // 参数带了entry=/application/access/detail/***的情况下，就重定向到申请访问列表页面
        if (to.query.entry && to.query.entry.indexOf('/application/access/detail/') === 0) {
            next('/application/access/entry')
            return
        }
    }

    // 进入页面后判断用户有没有手机号和注册申请,但以下页面不需要做判断
    if ([
        '/application/access/record',
        '/application/access/entry',
        '/application/access/detail/:id',
        '/application/access/invite/:info',
        '/application/access/apply',
        '/application/bill/abcAppPay/:token/:payId',
        '/',
        '/login'
    ].indexOf(to.name) === -1) {
        try {
            const user = await UserStoreOld.getAjax()
            // 判断这个人人有没有手机号
            {
                const RegisterWhiteList = [
                    '/',
                    '/login',
                    '/bindPhone',
                    '/registerApplyApprovalDetail'
                ]
                if (RegisterWhiteList.indexOf(to.name) === -1) {
                    if (!user.phone) {
                        next('/bindPhone')
                        return
                    }
                }
            }

            // // 每次进入页面前判断用户是否注册了，如果没注册得话
            // const RegisterWhiteList = [
            //     '/',
            //     '/login',
            //     '/bindPhone',
            //     '/chooseRole',
            //     '/registerForm',
            //     '/registerApplyApprovalDetail'
            // ]
            // if (RegisterWhiteList.indexOf(to.name) === -1) {
            //     const applyRegisterApplyResult = await UserService.getRegisterApplyApprovalDetail()
            //     if (!(user.isAdminUser && !applyRegisterApplyResult)) {
            //         // 先判断当前用户角色，如果是游客，就要判断这个用户得申请是不是审批中或者拒绝或者撤回，如果是审批中或者拒绝或者撤回状态，则代表用户可能是被
            //         // 离职了，然后重新申请的
            //         if (user.role === RoleEnum.VISITOR) {
            //             next('/registerApplyApprovalDetail')
            //         }
            //     }
            // }
            next()
        } catch (e) {
            if (e instanceof LogoutError) {
                if (['/', '/login'].indexOf(to.name) === -1) {
                    location.replace(process.env.VUE_APP_BASE_PATH + '/login')
                }
                next()
            } else {
                ErrorHandle.handle(e)
            }
        }
    } else {
        next()
    }
})

router.afterEach(async (to) => {
    document.getElementsByTagName('title')[0].innerText = to.meta.title
    // 部分页面不需要微信sdk
    if (['abcAppPay'].indexOf(to.name) === -1) {
        await WechatService.initConfig()
    }
})

export default router
