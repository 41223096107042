/**
 * 账单类型
 */
export default {
    OTHER_FEE: '00', // 其他
    OTHER_FEE__LABEL: '其他',

    WATER_FEE: '02', // 水费充值
    WATER_FEE__LABEL: '水费充值',

    ELECTRICITY_FEE: '03', // 电费
    ELECTRICITY_FEE__LABEL: '电费充值',

    MANAGE_FEE: '04', // 管理费
    MANAGE_FEE__LABEL: '管理费',

    RENT_FEE: '05', // 租金
    RENT_FEE__LABEL: '租金',

    CLEAN_FEE: '06', // 清洁
    CLEAN_FEE__LABEL: '清洁费',

    AMMETER_ELECTRICITY_FEE: '07', // 电表用电费用
    AMMETER_ELECTRICITY_FEE__LABEL: '电表用电费用',

    AMMETER_PUBLIC_STALL_FEE: '08', // 电表公共用电公摊费用
    AMMETER_PUBLIC_STALL_FEE__LABEL: '电表公共用电公摊费用',

    AMMETER_LOSS_FEE: '09', // 电表损耗用电公摊费用
    AMMETER_LOSS_FEE__LABEL: '电表损耗用电公摊费用',

    WATER_METER_FRR: '10', // 水表用水费用
    WATER_METER_FRR__LABEL: '水表用水费用'
}
