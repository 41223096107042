import VisVisitorApplyEntity from '@/support/v1/entity/VisVisitorApplyEntity'
import SysDepartEntity from '@/support/v1/entity/SysDepartEntity'
import VisVisitorEntity from '@/support/v1/entity/VisVisitorEntity'
import Require from '@/plugins/Require'
import VisVisitorInviteEntity from '@/support/v1/entity/VisVisitorInviteEntity'
import Uuid from '@/plugins/Uuid'
import VisVisitorPersonalEntity from '@/support/v1/entity/VisVisitorPersonalEntity'
import ApprovalState2Enum from '@/enums/ApprovalState2Enum'

export default {

    // 二次确认状态
    twoConfirmStataEnum: {
        NO_CONFIRM: 0,
        NO_CONFIRM__LABEL: '无需确认',
        CHECK_IN: 1,
        CHECK_IN__LABEL: '待登记',
        CONFIRMING: 2,
        CONFIRMING__LABEL: '待确认',
        CONFIRM: 3,
        CONFIRM__LABEL: '已确认',
        REJECT: 4,
        REJECT__LABEL: '已拒绝'
    },

    /**
     * 获取访客申请草稿
     * @return {Promise<VisVisitorApplyEntity>}
     */
    async getDraft() {
        const result = await Require.get('/app/visitor/visitor-apply/my-draft')
        if (result.data.result) {
            return VisVisitorApplyEntity.createInstance({
                ...result.data.result,
                visVisitorEntity: {
                    ...(result.data.result.visitorPersonal || {})
                }
            })
        }
        return null
    },

    /**
     * 保存草稿
     * @param visVisitorApplyEntity {VisVisitorApplyEntity}
     * @param page {number}
     * @return {Promise<{
     *     id: number,
     *     receptionistId: number,
     *     visVisitorEntity: {
     *         id: number
     *     }
     * }>}
     */
    async saveDraft(visVisitorApplyEntity, page) {
        if (page === 1) {
            const result = await Require.post('/app/visitor/visitor-apply/save-receptionist-info', {
                id: visVisitorApplyEntity.id,
                receptionistDepartId: visVisitorApplyEntity.receptionistDepartId,
                receptionistPhone: visVisitorApplyEntity.receptionistPhone,
                receptionistRealName: visVisitorApplyEntity.receptionistRealName
            })
            return {
                id: result.data.result.id,
                receptionistId: result.data.result.receptionistId,
                visVisitorEntity: {
                    id: (result.data.result.visitorPersonal || {}).id
                }
            }
        } else {
            const result = await Require.put('/app/visitor/visitor-apply', {
                ...visVisitorApplyEntity,
                visitorPersonal: {
                    ...(visVisitorApplyEntity.visVisitorEntity || {})
                }
            })
            return {
                id: result.data.result.id,
                receptionistId: result.data.result.receptionistId,
                visVisitorEntity: {
                    id: (result.data.result.visitorPersonal || {}).id
                }
            }
        }
    },

    /**
     * 根据用户名，手机号后4位获取用户的机构
     * @param realName {string}
     * @param phoneEnd4 {string}
     * @return {Promise<SysDepartEntity[]>}
     */
    async getOrgByPhoneOrName(realName, phoneEnd4) {
        const result = await Require.get(`/app/visitor/visitor-apply/depart/list`, {
            params: {
                realName,
                phoneLastFourDigits: phoneEnd4
            }
        })
        return (result.data.result || []).map(v => {
            return SysDepartEntity.createInstance({
                id: v.id,
                orgCode: v.orgCode,
                departName: v.departName
            })
        })
    },

    /**
     * 获取我的访客信息
     * @return {Promise<VisVisitorEntity>}
     */
    async getMyInfo() {
        const visVisitorEntity = new VisVisitorEntity()
        visVisitorEntity.visitorName = '测试'
        visVisitorEntity.phone = '13118895984'
        return visVisitorEntity
    },

    /**
     * 上传人像
     * @param file {File}
     * @return {Promise<string>}
     */
    async uploadFaceFile(file) {
        const formData = new FormData()
        formData.append('file', file)
        const result = await Require.post('/face/visitor/save', formData)
        return result.data.result
    },

    /**
     * 访客申请
     * @param id {number}
     * @return {Promise<void>}
     */
    async applyOfVisVisitorApplyId(id) {
        await Require.put(`/app/visitor/visitor-apply/commit/${id}`)
    },

    /**
     * 获取申请列表
     * @param page {number}
     * @param size {number}
     * @param bpmStatus {string}
     * @return {Promise<{total: number, size, page, list: VisVisitorApplyEntity[]}>}
     */
    async getAccessRecordPage(page, size, bpmStatus) {
        const result = await Require.get('/app/visitor/visitor-apply/my-page', {
            params: {
                pageNo: page,
                pageSize: size,
                bpmStatus
            }
        })
        return {
            page,
            size,
            total: result.data.result.total,
            list: result.data.result.records.map(vars => VisVisitorApplyEntity.createInstance(vars))
        }
    },

    /**
     * 根据id获取申请详情
     * @param id {number}
     */
    async getApplyById(id) {
        const result = await Require.get(`/app/visitor/visitor-apply/${id}`)
        return VisVisitorApplyEntity.createInstance({
            ...result.data.result,
            visVisitorEntity: {
                ...result.data.result.visitorPersonal
            }
        })
    },

    /**
     * 撤回申请
     * @param id {string}
     * @return {Promise<void>}
     */
    async withdraw(id) {
        await Require.put(`/app/visitor/visitor-apply/invalid/${id}`)
    },

    /**
     * 获取通行权限
     * @return {Promise<{
     *      privilegeGroupId: string,
     *      privilegeGroupName: string,
     *      remark: string,
     *      isDefault: string
     * }[]>}
     */
    async getAccessPermissionList() {
        const result = await Require.get('/app/visitor/visitor-permission/page?pageNo=1&pageSize=100')
        return result.data.result.records || []
    },

    /**
     * 邀请访问
     * @return {Promise<number>}
     */
    async invite(visVisitorInviteEntity) {
        const result = await Require.post('/visitor/visitor-invite', visVisitorInviteEntity)
        return result.data.result.id
    },

    /**
     * 获取邀请二维码有效信息
     * @param id {number}
     * @return {Promise<string>}
     */
    async getInviteQrcodeInfoByInviteId(id) {
        const key = Uuid.getUuid()
        await Require.post('/visitor/visitor-invite/set-qr-code-validity-period', {}, {
            params: {
                visitorInviteId: id,
                qrCodeUniqueFlag: key
            }
        })
        return key
    },

    /**
     * 获取访问邀请列表
     * @param page {number}
     * @param size {number}
     * @return {Promise<{total: number, size, page, list: VisVisitorInviteEntity[]}>}
     */
    async getInviteList(page, size) {
        const result = await Require.get('/visitor/visitor-invite/my-page', {
            params: {
                pageNo: page,
                pageSize: size
            }
        })
        return {
            page,
            size,
            total: result.data.result.total,
            list: result.data.result.records.map(vars => VisVisitorInviteEntity.createInstance(vars))
        }
    },

    /**
     * 获取邀请详情
     * @param id
     * @return {Promise<VisVisitorInviteEntity>}
     */
    async getInviteDetail(id) {
        const result = await Require.get(`/visitor/visitor-invite/${id}`)
        return VisVisitorInviteEntity.createInstance(result.data.result)
    },

    /**
     * 获取审批列表
     * @deprecated
     * @param page {number}
     * @param size {number}
     * @param state {string}
     * @return {Promise<{total: number, size, page, list: VisVisitorApplyEntity[]}>}
     */
    async getApprovalList(page, size, state) {
        return {
            page,
            size,
            total: 100,
            list: [1, 2, 3, 4, 5, 6, 7].map(id => {
                return VisVisitorApplyEntity.createInstance({
                    id,
                    receptionistRealName: '小明',
                    applyTime: '2022-10-10 10:10:10',
                    bpmStatus: state,
                    visVisitorEntity: {
                        visitorName: '小明'
                    }
                })
            })
        }
    },

    /**
     * 根据id获取审批详情
     * @param id {number}
     * @return {VisVisitorApplyEntity}
     */
    async getApprovalDetailById(id) {
        // return this.getApplyById(id)
        const result = await Require.get(`/app/visitor/visitor-task/visitor-apply/${id}`)
        return VisVisitorApplyEntity.createInstance({
            ...result.data.result,
            visVisitorEntity: result.data.result.visitorPersonal
        })
    },

    /**
     * 审批申请
     * @param processInstanceId {string}
     * @param isReject {'00'|'01'}
     * @param rejectReason {string}
     * @return {Promise<void>}
     */
    async approvalApply(processInstanceId, isReject, rejectReason = undefined) {
        await Require.put(`/workflow/my-to-do/handle-task`, {
            id: processInstanceId,
            isReject,
            taskHandleExtras: {
                comment: rejectReason
                // taskStatus: ApprovalState2Enum.REJECT
            }
        })
    },

    /**
     * 设置授权访问权限
     * @params id {number} 访问申请id
     * @params permissions {{
     *     privilegeGroupId: string,
     *     privilegeGroupName: string
     * }[]} 访问申请id
     * @return {Promise<void>}
     */
    async setApplyAccessPermission(id, permissions) {
        await Require.post('/visitor/visitor-permission/add-of-apply', {
            id,
            visitorPermissionList: permissions
        })
    },

    /**
     * 微信登录
     * @param redirectUrl {string} 回调地址
     * @return {Promise<void>}
     */
    async wxOAuth2(redirectUrl) {
        if (!redirectUrl) {
            redirectUrl = location.origin + process.env.VUE_APP_BASE_PATH + '/application/access/entry'
        }
        // if (location.href.indexOf('?') > -1) {
        //     redirectUrl = location.href.substring(0, location.href.indexOf('?'))
        // }
        const result = await Require.get('/sys/getWxMpLoginUrl', {
            params: {
                redirectUrl,
                appid: process.env.VUE_APP_WECHAT_APP_ID
            }
        })
        location.replace(result.data.result)
    },

    async wxLogin(code, state) {
        const result = await Require.post('/app/visitor/login/mp/login', {}, {
            params: {
                code,
                state,
                appid: process.env.VUE_APP_WECHAT_APP_ID
            }
        })
        window.localStorage.setItem('X-Access-Token', result.data.result.token)
    },

    /**
     * 邀请访问表单提交w
     * @type visVisitorPersonalEntity {VisVisitorPersonalEntity}
     * @return {number}
     */
    async inviteFormApply(visVisitorPersonalEntity) {
        const result = await Require.post('/app/visitor/visitor-personal/invite', visVisitorPersonalEntity)
        return result.data.result.encryptVisitorRecordId
    },

    /**
     * 根据邀请id获取被访问人的表单
     * @param inviteId
     * @return {Promise<VisVisitorPersonalEntity>}
     */
    async getInviteFormByInviteId(inviteId) {
        const result = await Require.get(`/app/visitor/visitor-personal/invite/${inviteId}`)
        if (result.data.result) {
            return VisVisitorPersonalEntity.createInstance(result.data.result)
        }
        return null
    },

    /**
     * 根据邀请id获取被访问人的表单
     * @param processInstanceId {string} 流程实例id
     * @return {Promise<{
     *      assignee: string,
     *      assigneeRealName: string,
     *      id: string,
     *      name: string,
     *      comment: string,
     *      processDefinitionId: string,
     *      processInstanceId: string,
     *      startTime: string,
     *      taskStatus: string
     * }[]>}
     */
    async getProcessNodesByProcessInstanceId(processInstanceId) {
        const result = await Require.get(`/app/visitor/visitor-apply/detail/${processInstanceId}`)
        return result.data.result
    },

    /**
     * 根据id获取邀请访问信息，只能给被邀请人用
     * @param id
     * @return {Promise<VisVisitorInviteEntity>}
     */
    async getInviteInfoByIdForInvitee(id) {
        const result = await Require.get(`/app/visitor/visitor-personal/visitor-invite/${id}`)
        return VisVisitorInviteEntity.createInstance(result.data.result)
    },

    /**
     * 获取邀请访问到访确认列表
     * @param inviteId {string|number} 邀请访问id
     * @return {Promise<{
     *      id: number,
     *     actualVisitStartTime: string,
     *     againConfrimationStatus: string,
     *     visVisitorPersonalEntity: VisVisitorPersonalEntity
     * }[]>}
     */
    async getInviteAccessToTheVisitList(inviteId) {
        const result = await Require.get(`/app/visitor/visitor-record/invite/page/${inviteId}?pageNo=1&pageSize=50`)
        return (result.data.result.records || []).map(item => {
            return {
                id: item.id,
                actualVisitStartTime: item.actualVisitStartTime,
                againConfrimationStatus: item.againConfrimationStatus,
                visVisitorPersonalEntity: VisVisitorPersonalEntity.createInstance(item.visitorPersonal)
            }
        })
    },

    /**
     * 根据id获取到访人员
     * @param id {string|number} 邀请访问id
     * @return {Promise<{
     *      id: number,
     *     actualVisitStartTime: string,
     *     againConfrimationStatus: string,
     *     visVisitorPersonalEntity: VisVisitorPersonalEntity
     * }>}
     */
    async getInviteAccessToTheVisitById(id) {
        const result = await Require.get(`/app/visitor/visitor-record/${id}`)
        return {
            id: result.data.result.id,
            actualVisitStartTime: result.data.result.actualVisitStartTime,
            againConfrimationStatus: result.data.result.againConfrimationStatus,
            visVisitorPersonalEntity: VisVisitorPersonalEntity.createInstance(result.data.result.visitorPersonal)
        }
    },

    /**
     * 访客通行二次确认通过
     * @param id
     * @return {Promise<void>}
     */
    async inviteAccessToTheVisitToConfirmPass(id) {
        await Require.get(`/app/visitor/visitor-record/confrimation/${id}?flag=1`)
    },

    /**
     * 访客通行二次确认拒绝
     * @param id
     * @return {Promise<void>}
     */
    async inviteAccessToTheVisitToConfirmReject(id) {
        await Require.get(`/app/visitor/visitor-record/confrimation/${id}?flag=0`)
    },

    /**
     * 根据申请id获取访客二次确认
     * @param applyId {number}
     * @return {Promise<{
     *      id: number,
     *     actualVisitStartTime: string,
     *     againConfrimationStatus: string
     * }|null>}
     */
    async getAccessToTheVisitToConfirmByApplyId(applyId) {
        const result = await Require.get(`/app/visitor/visitor-record/apply/${applyId}`)
        if (result.data.result) {
            return {
                id: result.data.result.id,
                actualVisitStartTime: result.data.result.actualVisitStartTime,
                againConfrimationStatus: result.data.result.againConfrimationStatus,
                visVisitorPersonalEntity: VisVisitorPersonalEntity.createInstance(result.data.result.visitorPersonal || {})
            }
        } else {
            return null
        }
    },

    /**
     * 根据邀请id获取被访问人的表单(邀请人专用)
     * @param inviteId
     * @return {Promise<{
     *      id: number,
     *     actualVisitStartTime: string,
     *     againConfrimationStatus: string
     * }|null>}
     */
    async getInviteFormByInviteIdForBeInviter(inviteId) {
        const result = await Require.get(`/app/visitor/visitor-record/one/${inviteId}`)
        if (result.data.result && result.data.result.againConfrimationFlag === 1) {
            return {
                id: result.data.result.id,
                actualVisitStartTime: result.data.result.actualVisitStartTime,
                againConfrimationStatus: result.data.result.againConfrimationStatus,
                // visVisitorPersonalEntity: VisVisitorPersonalEntity.createInstance(result.data.result.visitorPersonal)
            }
        } else {
            return null
        }
    },

    /**
     * 根据邀请id获取被访问人的表单(申请人人专用)
     * @param applyId
     * @return {Promise<{
     *      id: number,
     *     actualVisitStartTime: string,
     *     againConfrimationStatus: string
     * }|null>}
     */
    async getInviteFormByApplyIdForBeApply(applyId) {
        const result = await Require.get(`/app/visitor/visitor-record/apply/one/${applyId}`)
        if (result.data.result) {
            return {
                id: result.data.result.id,
                encryptId: result.data.result.encryptId,
                actualVisitStartTime: result.data.result.actualVisitStartTime,
                againConfrimationStatus: result.data.result.againConfrimationStatus,
                // visVisitorPersonalEntity: VisVisitorPersonalEntity.createInstance(result.data.result.visitorPersonal)
            }
        } else {
            return null
        }
    }
}
