// 性别枚举（非必要请使用数据字典）
export default {
    BOY: '1',
    GIRL: '2',

    /**
     * 获取label
     * @param value 枚举值
     * @return string
     */
    getLabel(value) {
        switch (value) {
            case this.BOY:
                return '男'
            case this.GIRL:
                return '女'
        }
    }
}
