import {createApp} from 'vue'
import App from './App.vue'
import router from './router/router'
import 'vant/lib/index.css'
import './App.less'
import VanDialog from 'vant/es/dialog'
import * as VConsole from 'vconsole'
import LogoutError from '@/errors/LogoutError'
// import VConsole from 'vconsole'
import AccessService from '@/support/v1/service/AccessService'
const app = createApp(App)

if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'sit') {
    new VConsole()
    // localStorage.setItem('X-Access-Token', 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE3MDI5NzE1NjYsInVzZXJuYW1lIjoiZmY4MDgwODE4OWIwODM4NTAxODliMDgzODU5NzAwMDAifQ.wSxOtZrep7e6_-xXcP8YvhrXqGALLbLOk9tsPXyYPGg')
}

// 如果入口是访客申请，那就修改登陆失效的异常处理
if (location.pathname.indexOf('/application/access/entry') !== -1 ||
    location.pathname.indexOf('/application/access/detail') !== -1 ||
    location.pathname.indexOf('/application/access/apply') !== -1) {
    LogoutError.prototype.handle = function () {
        VanDialog.alert({
            message: '登陆过期，请重新登陆！'
        }).then(() => {
            window.localStorage.removeItem('tenant-id')
            window.localStorage.removeItem('X-Access-Token')
            router.replace('/application/access/entry')
        })
    }
}

// 如果是访客邀请，那就修改登陆失效的异常处理 a
if (location.pathname.indexOf('/application/access/invite/') !== -1) {
    LogoutError.prototype.handle = function () {
        window.localStorage.removeItem('tenant-id')
        window.localStorage.removeItem('X-Access-Token')
        AccessService.wxOAuth2(location.origin + location.pathname)
    }
}


app.use(router)
app.use(VanDialog)
app.mount('#app')
// if (process.env.NODE_ENV === 'production') {
//     app.config.errorHandler = function (err, instance, type, throwInDev) {
//         console.log(err)
//         window.JzWebLog.insertLog(JSON.stringify({
//             message: err.message,
//             instance,
//             type,
//             throwInDev
//         }))
//         console.error(JSON.stringify({
//             message: err.message,
//             instance,
//             type,
//             throwInDev
//         }))
//     }
// }


