import UserService from '../services/UserService'
import router from '@/router/router'

export default class UserStore {
    /**
     *
     * @type {UserEntity|null}
     * @private
     */
    static _store = null

    /**
     * 获取用户信息
     * @return {UserEntity}
     */
    static async get() {
        if (!UserStore._store) {
            await UserStore.getAjax()
        }
        return UserStore._store
    }

    /**
     * 从服务器获取用户信息
     * @return {UserEntity}
     */
    static async getAjax() {
        const user = await UserService.getUserInfo()
        UserStore.set(user)
        return UserStore._store
    }

    /**
     * 设置用户信息
     * @param userEntity {UserEntity}
     */
    static set(userEntity) {
        UserStore._store = userEntity
    }

    /**
     * 清空数据
     */
    static clear() {
        UserStore._store = null
    }
}
