export default class VisVisitorPersonalEntity {

    /**
     * @type {number}
     */
    id


    /**
     * @type {number}
     */
    visitorApplyId


    /**
     * @type {number}
     */
    visitorInviteId


    /**
     * @type {number}
     */
    visitorRecordId

    /**
     * @type {string}
     */
    encryptVisitorRecordId


    /**
     * @type {string}
     */
    visitorName


    /**
     * @type {string}
     */
    gender


    /**
     * @type {string}
     */
    phone


    /**
     * @type {string}
     */
    visitorWorkUnit


    /**
     * @type {string}
     */
    plateNo


    /**
     * @type {string}
     */
    verificationCode


    /**
     * @type {string}
     */
    visitorPhoto


    /**
     * @type {string}
     */
    qrCode


    /**
     * @type {string}
     */
    appointRecordId


    /**
     * @type {string}
     */
    orderId


    /**
     * @type {number}
     */
    tenantId


    /**
     * @type {string}
     */
    createBy


    /**
     * @type {number}
     */
    createTime


    /**
     * @type {string}
     */
    updateBy


    /**
     * @type {number}
     */
    updateTime


    /**
     * @type {number}
     */
    version

    /**
     * @type {string}
     */
    qrCodeUniqueFlag

    // vo------------------------------

    /**
     * @type {string}
     */
    visitPurpose


    /**
     * 创建一个实例
     * @return VisVisitorPersonalEntity
     */
    static createInstance(vars) {
        const entity = new VisVisitorPersonalEntity()
        for (let k in entity) {
            entity[k] = vars[k]
        }
        return entity
    }
}
