import BaseErrorAbstract from '../common/BaseErrorAbstract'
import VanDialog from 'vant/es/dialog'
import router from '../router/router'

// 登录过期错误
export default class LogoutError extends BaseErrorAbstract {
    constructor(message) {
        super(message)
    }

    handle() {
        VanDialog.alert({
            message: '登陆过期，请重新登陆！'
        }).then(() => {
            window.localStorage.removeItem('tenant-id')
            window.localStorage.removeItem('X-Access-Token')
            router.replace('/login')
        })
        super.handle()
    }
}
