import UserEntity from '../entity/UserEntity'
import Require from '../plugins/Require'
import DictStore from '../store/DictStore'
import SexEnum from '../enums/SexEnum'
import RoleEnum from '../enums/RoleEnum'

export default {
    /**
     * 微信登录
     * @return {Promise<void>}
     */
    async wxOAuth2() {
        let redirectUrl = location.origin + process.env.VUE_APP_BASE_PATH + '/login'
        // if (location.href.indexOf('?') > -1) {
        //     redirectUrl = location.href.substring(0, location.href.indexOf('?'))
        // }
        const result = await Require.get('/sys/getWxMpLoginUrl', {
            params: {
                redirectUrl,
                appid: process.env.VUE_APP_WECHAT_APP_ID
            }
        })
        location.replace(result.data.result)
    },

    async wxLogin(code, state) {
        const result = await Require.post('/sys/mp/login', {}, {
            params: {
                code,
                state,
                appid: process.env.VUE_APP_WECHAT_APP_ID
            }
        })
        window.localStorage.setItem('X-Access-Token', result.data.result.token)
    },

    /**
     * 获取手机验证码
     * @param phone {string} 手机号
     * @return {Promise<void>}
     */
    async getPhoneCode(phone) {
        await Require.post('/sys/sms', {
            mobile: phone,
            smsmode: 3
        })
    },

    /**
     * 绑定手机号
     * @param phone {string} 手机号
     * @param code {string} 验证码
     */
    async bindPhone(phone, code) {
        await Require.post('/sys/phone/bind', {
            phone,
            captcha: code
        })
    },

    /**
     * 绑定手机号，体验版用
     * @param phone {string} 手机号
     * @param code {string} 验证码
     */
    async bindPhoneExperience(phone, code) {
        await Require.post('/sys/phone/bind/ys', {
            phone,
            captcha: code
        })
    },

    /**
     * 获取用户信息
     * @return {Promise<UserEntity>}
     */
    async getUserInfo() {
        const result = await Require.get('/sys/user/info')
        const user = new UserEntity()
        user.id = result.data.result.id
        user.phone = result.data.result.phone
        user.name = result.data.result.realname
        user.avatar = result.data.result.avatar
        user.role = result.data.result.registerRoleType
        user.inputDataFlag = result.data.result.inputDataFlag
        user.sex = result.data.result.sex + '' || SexEnum.BOY
        user.orgCode = result.data.result.orgCode
        user.orgName = result.data.result.orgCodeTxt
        user.emergencyContactName = result.data.result.contactName || ''
        user.emergencyContactPhone = result.data.result.contactPhone || ''
        user.originAddress = result.data.result.registerAddress || ''
        user.nowAddress = result.data.result.liveAddress || ''
        user.frontPhoto = result.data.result.frontPhoto
        user.facePhoto = result.data.result.faceUrl
        user.isAdminUser = result.data.result.registerRoleType === RoleEnum.STAFF_MEMBER
        user.email = result.data.result.email
        user.tenantId = result.data.result.tenantId
        user.carNum = result.data.result.plateNumber
        return user
    },

    /**
     * 注册申请
     * @param id {string|number} id，获取草稿时得到的id
     * @return {Promise<void>}
     */
    async registerApply(id) {
        await Require.post(`/app/register/submit?id=${id}`)
    },

    /**
     * 注册申请保存草稿，返回保存的id
     * @param id {string|number|null}
     * @param username {string} 姓名
     * @param phone {string} 手机号
     * @param orgId {number} 机构id
     * @param orgName {string} 机构名称
     * @param sexType {string} 性别类型
     * @param sexTypeName {string} 性别类型
     * @param emergencyContact {string} 紧急联系人电话
     * @param emergencyContactName {string} 紧急联系人姓名
     * @param originAccess {string} 户口所在地
     * @param nowAccess {string} 当前居住地
     * @param carNum {string} 车牌号
     * @param idPhoto {string} 正面照
     * @param email? {string} 邮箱
     * @return {Promise<number>}
     */
    async registerApplySaveDraft(id, username, phone, orgId, orgName, sexType, sexTypeName, emergencyContact, emergencyContactName, originAccess, nowAccess, carNum, idPhoto, email) {
        if (!id) {
            const result = await Require.post('/app/register/add', {
                roleId: RoleEnum.STAFF_MEMBER,
                roleName: '园区人员',
                tenantId: parseInt(process.env.VUE_APP_TENANT_ID),
                gender: sexType,
                deptName: orgName,
                deptId: orgId,
                createName: username,
                phone,
                address: nowAccess,
                carNumber: carNum,
                emergencyContactName: emergencyContactName,
                emergencyContactPhone: emergencyContact,
                nativeAddress: originAccess,
                photo: idPhoto,
                email
            })
            return result.data.result
        } else {
            const result = await Require.post('/app/register/update', {
                id,
                createName: username,
                address: nowAccess,
                carNumber: carNum,
                deptId: orgId,
                deptName: orgName,
                emergencyContactName: emergencyContactName,
                emergencyContactPhone: emergencyContact,
                gender: sexType,
                nativeAddress: originAccess,
                photo: idPhoto,
                email
            })
            return result.data.result.id
        }
    },

    /**
     * 根据获取注册申请草稿
     * @return {Promise<{
     *     id: number,
     *     username?: string,
     *     phone?: string,
     *     orgId?: string|number,
     *     orgName?: string,
     *     sexType?: string,
     *     sexTypeName?: string,
     *     emergencyContact?: string,
     *     emergencyContactName?: string,
     *     originAccess?: string,
     *     nowAccess?: string,
     *     carNum?: string,
     *     idPhoto?: string,
     *     email?: string
     * }|null>}
     */
    async getRegisterApplyDraft() {
        const result = await Require.post('/app/register/draft')
        if (result.data.result) {
            return {
                id: result.data.result.id,
                username: result.data.result.createName,
                phone: result.data.result.phone,
                orgId: result.data.result.deptId,
                orgName: result.data.result.deptName,
                sexType: result.data.result.gender,
                sexTypeName: ((await DictStore.getByParentKeyAndValue('sex', result.data.result.gender)) || {}).text,
                emergencyContact: result.data.result.emergencyContactPhone,
                emergencyContactName: result.data.result.emergencyContactName,
                originAccess: result.data.result.nativeAddress,
                nowAccess: result.data.result.address,
                carNum: result.data.result.carNumber,
                idPhoto: result.data.result.photo,
                email: result.data.result.email
            }
        } else {
            return null
        }
    },

    /**
     * 更新用户消息
     * @param user {UserEntity}
     * @return {Promise<void>}
     */
    async updateUserInfo(user) {
        await Require.post('/sys/user', {
            realname: user.name,
            avatar: user.avatar,
            contactName: user.emergencyContactName,
            contactPhone: user.emergencyContactPhone,
            liveAddress: user.nowAddress,
            registerAddress: user.originAddress,
            frontPhoto: user.frontPhoto,
            sex: user.sex,
            email: user.email
        })
    },

    /**
     * 获取注册申请审批详情
     * @return {Promise<{
     *     id: number,
     *     username?: string,
     *     phone?: string,
     *     orgId?: string|number,
     *     orgName?: string,
     *     sexType?: string,
     *     sexTypeName?: string,
     *     emergencyContact?: string,
     *     emergencyContactName?: string,
     *     originAccess?: string,
     *     nowAccess?: string,
     *     carNum?: string,
     *     idPhoto?: string,
     *     status?: string,
     *     reason?: string,
     *     approvalTime?: string,
     *     applyTime? :string,
     *     approvalId? :string|number,
     *     email?:string
     * }|null>}
     */
    async getRegisterApplyApprovalDetail() {
        const result = await Require.post('/app/register/self')
        if (result.data.result && result.data.result.id) {
            return {
                id: result.data.result.id,
                username: result.data.result.register.createName,
                phone: result.data.result.register.phone,
                orgId: result.data.result.register.deptId,
                orgName: result.data.result.register.deptName,
                sexType: result.data.result.register.gender,
                sexTypeName: ((await DictStore.getByParentKeyAndValue('sex', result.data.result.register.gender)) || {}).text,
                emergencyContact: result.data.result.register.emergencyContactPhone,
                emergencyContactName: result.data.result.register.emergencyContactName,
                originAccess: result.data.result.register.nativeAddress,
                nowAccess: result.data.result.register.address,
                carNum: result.data.result.register.carNumber,
                idPhoto: result.data.result.register.photo,
                status: result.data.result.status,
                reason: result.data.result.approverContent,
                approvalTime: result.data.result.register.updateTime,
                applyTime: result.data.result.register.createTime,
                approvalId: result.data.result.id,
                email: result.data.result.register.email
            }
        } else {
            return null
        }
    },

    /**
     * 撤回注册申请
     * @param approvalId {number|string} 审批id
     * @return {Promise<void>}
     */
    async withdrawRegisterApply(approvalId) {
        await Require.post(`/app/register/revoke?approvalId=${approvalId}`)
    },

    /**
     * 上传人像
     * @param file {File}
     * @return {Promise<void>}
     */
    async setUserFace(file) {
        const formData = new FormData()
        formData.append('file', file)
        await Require.post('/face/update', formData)
    },

    // /**
    //  * 获取当前的租户id
    //  * @return {Promise<number>}
    //  */
    // async getNowTenantId() {
    //     return Config.tenantId
    // },

    async logout() {
        try {
            await Require.post('/sys/logout')
        } catch (e) {
            // 不管
        } finally {
            window.localStorage.removeItem('tenant-id')
            window.localStorage.removeItem('X-Access-Token')
        }
    }
}
