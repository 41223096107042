<template>
	<router-view/>
</template>

<script>

import DictStore from '@/store/DictStore'
import Uuid from '@/plugins/Uuid'

export default {
	name: 'App',
	data() {
		return {
			key: Uuid.getUuid()
		}
	},
	watch: {
		'$route': function () {
			this.key = Uuid.getUuid()
		}
	},
	async mounted() {
		await DictStore.initConfigLabel()
	}
}
</script>
