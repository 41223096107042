// 数据字典实例
export default class DictEntity {

    /**
     * 父级类型
     * @type {string}
     */
    parentKey

    /**
     * 值
     * @type {string}
     */
    value

    /**
     * text
     * @type {string}
     */
    text

    /**
     * label
     * @type {string}
     */
    label

    /**
     * title
     * @type {string}
     */
    title
}
