export default class VisVisitorEntity {

    /**
     * @type {number}
     */
    id


    /**
     * @type {string}
     */
    unionId


    /**
     * @type {string}
     */
    openId


    /**
     * @type {string}
     */
    visitorName


    /**
     * @type {string}
     */
    gender


    /**
     * @type {string}
     */
    phone


    /**
     * @type {string}
     */
    visitorWorkUnit


    /**
     * @type {string}
     */
    plateNo


    /**
     * @type {string}
     */
    verificationCode


    /**
     * @type {string}
     */
    qrCode


    /**
     * @type {string}
     */
    orderId


    /**
     * @type {number}
     */
    tenantId


    /**
     * @type {string}
     */
    createBy


    /**
     * @type {number}
     */
    createTime


    /**
     * @type {string}
     */
    updateBy


    /**
     * @type {number}
     */
    updateTime


    /**
     * @type {number}
     */
    version

    /**
     * @type {string}
     */
    visitorPhoto

    /**
     * 创建一个实例
     * @return VisVisitorEntity
     */
    static createInstance(vars) {
        const entity = new VisVisitorEntity()
        for (let k in entity) {
            entity[k] = vars[k]
        }
        return entity
    }
}
